//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from "vuex";
import RestrictedMessage from "@/components/errorBlocks/RestrictedMessage";


  export default {
    name: 'PersonalCertificate',
    components: {RestrictedMessage},
    computed: {
      ...mapState([
        'personalCertificate',
        'personalCertificateLoading',
        'dsRequest',
        'hasAvailableDsSlot',
      ]),
      ...mapGetters([
        'isDsRequestWaitStatus',
        'isDsRequestRejectedStatus',
        'isDsRequestApprovedStatus',
        'isAllowDsRequest',
      ])
    }
  }
